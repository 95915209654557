import React, { useEffect, useState } from 'react'
import dropin from 'braintree-web-drop-in'
import { Button } from 'reactstrap';

export default function BraintreeDropIn (props) {
  const { show, onPaymentCompleted, dueAmount } = props;
  const [isBraintreeDisabled, setIsBraintreeDisabled] = useState(false)
  const [showBraintree, setShowBraintree] = useState(false); // New state for visibility
  const [braintreeInstance, setBraintreeInstance] = useState(undefined)

  useEffect(() => {
    if (show && showBraintree) {
      console.log('show!')
      const initializeBraintree = () => dropin.create({
        // insert your tokenization key or client token here
        // authorization: 'sandbox_24cwctx5_vc32xnxz6tht5jqg',
        authorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION,
        container: '#braintree-drop-in-div',
        venmo: {},
        applePay: {
          displayName: 'Luxe Mobile IV',
          paymentRequest: {
            total: {
              label: 'Luxe Mobile IV Service',
              amount: dueAmount
            },
            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with all
            // Apple Pay transactions as a best practice
            requiredBillingContactFields: ['postalAddress']
          },
        }
      }, function (error, instance) {
        if (error) { console.error(error) } else { setBraintreeInstance(instance); }
      });

      if (braintreeInstance) {
        braintreeInstance
          .teardown()
          .then(() => {
            initializeBraintree();
          });
      } else {
        initializeBraintree();
      }
    }
  }, [show, showBraintree])

  const toggleBraintreeVisibility = () => {
    setShowBraintree(!showBraintree);
  };

  return (
        <div
            style={{ display: `${show ? 'block' : 'none'}` }}
        >
           {showBraintree && ( // Only render if showBraintree is true
        <div id={'braintree-drop-in-div'} />
           )}
<br/>
            <Button
                className={'tip-button'}
                style={{
                  backgroundColor: isBraintreeDisabled ? '#cccccc' : '#0F9E82', // Greyed out when disabled
                  color: isBraintreeDisabled ? '#666666' : 'white', // Darker text when disabled
                  padding: '10px 20px', // Padding: 10px top and bottom, 20px left and right
                  fontSize: '16px', // Font size
                  border: 'none', // No border
                  borderRadius: '5px', // Rounded corners
                  cursor: 'pointer', // Cursor changes to a pointer on hover
                  outline: 'none', // Remove outline on focus (for accessibility, consider keeping it or styling it appropriately)
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' // Subtle shadow for depth
                }}
                type="primary"
                disabled={(showBraintree && !braintreeInstance) || isBraintreeDisabled}
                onClick={() => {
                  console.log('showBraintree')
                  if (!showBraintree) {
                    toggleBraintreeVisibility(); // Show Braintree drop-in
                  } else if (braintreeInstance) {
                    braintreeInstance.requestPaymentMethod(
                      (error, payload) => {
                        if (error) {
                          console.error(error);
                        } else {
                          const paymentMethodNonce = payload.nonce;
                          onPaymentCompleted(paymentMethodNonce, setIsBraintreeDisabled);
                        }
                      });
                  }
                }}
            >
                {showBraintree ? 'Pay' : 'Pay with Card, Venmo, or Apple/Google/Samsung Pay'}
            </Button>
        </div>
  )
}
